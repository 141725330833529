// ripple effect on hover
.ripple-animate {
    position: relative;
    overflow:hidden;

    &:hover:before{
        animation: ripple 1s ease;
    }

    &:hover {
        content:"";
        position:absolute; top:0; left:0;
        width:100%; height:100%;
        background-color:rgba(255, 255, 255, 0.7);
        border-radius:50%;
        transform:scale(0);
    }
}

@keyframes ripple{
	from{transform:scale(0); opacity:1;}
	to{transform:scale(3);opacity:0;}
}

// grow effect on hover:
.grow-animate-1 {
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
}

.grow-animate-2 {
    transition: all .2s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
}

//slideup and slidedown animate
.fadein, .fadeout {
    max-height: 0;
    overflow-y: hidden;
    -moz-transition: max-height 0.4s ease-in-out;
    -o-transition: max-height 0.4s ease-in-out;
    -webkit-transition: max-height 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out;
}
.fadein {
    max-height: 500px;
}