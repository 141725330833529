@import 'mixins';
@import 'variables';
/* custom tree */

.ant-table-wrapper {
  width: 100%;
}

.ant-tabs .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  // background-color: #F13047 !important;
  background-color: $secondary-color !important;
}

.ant-table-content {
  overflow-x: auto !important;
  min-height: 500px !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  @include background-color;
  @include border-color;
}
.ant-tree .ant-tree-switcher-leaf-line {
  &:before {
    border-left: 1px dashed #e0e0e0 !important;
  }
  &:after {
    border-left: 1px dashed #e0e0e0 !important;
  }
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  @include background-color;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  @include background-active-color;
}

.ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected
  .ant-tree-title {
  @include gradient-text;
}

/* custom tabs*/

.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        @include gradient-text();
        font-weight: bold;
        font-size: 16px;
      }
    }
    .ant-tabs-ink-bar {
      background: #0743fb;
    }
  }
}

/* custom switch*/

.ant-switch-checked {
  @include background-color();
}


/* custom radio */

.ant-radio-checked .ant-radio-inner {
  border-color: #013ffb !important;
}

.ant-radio-inner:after {
  @include background-color();
}

/*custom checkbox */

.ant-checkbox-input:focus + .ant-checkbox-inner, 
.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #013ffb !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @include background-color();
}

/* custom table header */
.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color:#e1e1e1 !important;
}

/* custom confirm content */
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}

.ant-row--hightlight {
  background-color: #eee;
  color: $secondary-color;
}

.ant-row--warning {
  td {
    color: $warning-color !important;
  }
}

.ant-row--error {
  td {
    color: $error-color !important;
  }
}

/* custom draggable */
.ant-table-content.active-draggable {
  cursor: grabbing;
}

/* custom ant-content-select */
.ant-select-item-option-content {
  overflow: unset !important;
  white-space: pre-wrap !important;
  text-overflow: unset !important;
}