@import "~antd/dist/antd.min.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "font.scss";
@import "variables";
@import "mixins";
@import "customize-antd.scss";
@import "animate.scss";

// customize scroll bar:
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: -webkit-linear-gradient(224.53deg,
      $secondary-color 0%,
      $secondary-color 99.2%);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px -webkit-linear-gradient(224.53deg, $secondary-color 0%, $secondary-color 99.2%);
  -webkit-box-shadow: inset 0 0 6px -webkit-linear-gradient(224.53deg, $secondary-color 0%, $secondary-color 99.2%);
  background-color: #f5f5f5;
}

// common style:
body {
  background: #f2f2f2;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;

  .text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-select--custom {
    width: 100%;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #f2f2f2 !important;
      border-radius: 7px;
      height: 40px;
      padding: 0.25rem 0.7rem;
      box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    }
  }

  .href-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  input,
  textarea {
    font-family: sans-serif;
  }

  .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text {
    color: $primary-color;
    display: inline-block;
    margin-left: 5px;
  }

  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $primary-color;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
    }
  }

  @keyframes bounce {
    0% {
      top: 30px;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }

    35% {
      height: 15px;
      border-radius: 50%;
      transform: scaleX(1);
    }

    100% {
      top: 0;
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  #app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .field-disabled {
    cursor: not-allowed;
  }

  .overlay-backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;

    img {
      background-color: transparent;
      height: 150px;
      width: 200px;
    }
  }

  .text-danger {
    color: $primary-color;
    font-weight: 800;
  }

  .text-primary {
    color: $secondary-color;
    font-weight: 800;
  }

  .img-loading {
    max-height: 50px;
    max-width: 50px;
  }

  .img-emty {
    width: inherit;
  }

  .input-icon {
    @include input-icon;
  }

  .component__title {
    @include input-title;
    color: #235a78;
    font-weight: bold;
  }

  .gradient-text {
    @include gradient-text();
  }

  .difference-text {
    text-decoration: line-through;
    color: $primary-color;
    margin-left: 5px;
  }

  .icon-fontsize {
    font-size: 17px;
    margin-left: 4px;
  }

  .component__text-area {
    width: 100%;
    height: 60px;
    min-height: 60px;
    padding: 0.5rem;
    font-size: 13px;
    font-weight: 400;
    background: #ffffff;
    border: 0;
    border-radius: 7px;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    outline: none;

    &.component__text-area--bordered {
      border: 1px solid #f2f2f2;
    }

    &.component__text-area--material {
      padding-left: 0rem !important;
      padding-right: 1.25rem !important;
      border-radius: 0 !important;
      border-bottom: 2px solid $input-border-color;
      background: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        border-bottom: 2px solid !important;
        @include border-color;

        &~i {
          @include gradient-text;
        }
      }
    }
  }

  .component__input {
    width: 100%;
    height: 40px;
    padding: 1rem 1.75rem 1rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-clip: padding-box;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    outline: none;

    &.component__input--align-right {
      text-align: right;
      padding: 1rem 1.2rem 1rem 0.75rem;
    }

    &.component__input--bordered {
      border: 1px solid #f2f2f2;
    }

    &.component__input--material {
      padding: 1rem 2rem 1rem 0rem !important;
      border-radius: 0 !important;
      border-bottom: 2px solid $input-border-color;
      background: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        border-bottom: 2px solid !important;
        @include border-color;

        &~i {
          @include gradient-text;
        }
      }
    }
  }

  .btn {
    white-space: nowrap;
    color: inherit;

    &:focus {
      box-shadow: none;
    }
  }

  .component__btn-primary {
    @include background-color;
    color: $white;
    border: none;
    border-radius: 7px;
    text-align: center;
    height: 38px;
    font-weight: 600;
    padding: 0 16px;

    &:hover {
      color: $white;
    }
  }

  .component__btn-toggle {
    background: #f1f1f6;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    height: 38px;
    border-radius: 7px;
    border: none;

    i,
    span {
      @include gradient-text();
    }

    i {
      margin-right: 10px;
    }

    span {
      font-weight: bold;
    }

    &.component__btn-toggle-active {
      @include background-color();
      border: none;

      i,
      span {
        @include white-text();
      }
    }

    &:hover {
      @include background-color();
      border: none;

      i,
      span {
        @include white-text();
      }
    }
  }

  .component__btn-outline-primary {
    border: 1px solid;
    @include border-radius-color;
    border-radius: 7px;
    height: 38px;
    // margin: 0 auto;
    @include gradient-text;
    font-weight: 500;

    &:hover {
      border: 1px solid transparent;
      @include background-color;
      color: #ffffff;
      -webkit-background-clip: initial;
      -moz-background-clip: initial;
      background-clip: initial;
      -webkit-text-fill-color: initial;
    }
  }

  .component__btn-cancel {
    @include background-color-delete();
    color: #ffffff;
    color: $white;
    border: none;
    border-radius: 7px;
    text-align: center;
    height: 38px;
    font-weight: 600;
    padding: 0 16px;

    &:hover {
      color: $white;
    }
  }

  .component__btn-save-template {
    background: #f2f2f2;
    color: $primary-color;
  }

  .component__btn-save-draft {
    background-color: #f2f2f2;

    span {
      @include gradient-text();
    }
  }

  .gradient-btn-icon {
    @include gradient-text();
    font-size: 20px;
  }

  .component__btn-delete {
    font-size: 20px;
    color: $primary-color;
    //@include gradient-text();
  }

  /* Page*/
  /* Master*/
  .page {

    &.page__master,
    &.page__detail {
      .page__header {
        margin-bottom: 10px;
        font-weight: bold;

        .page__title {
          @include gradient-text();
          font-size: 30px;
        }

        .page__id {
          color: #4f4f4f;
        }

        .page__navigate {
          font-size: 14px;
          display: flex;
          align-items: center;

          &:hover {
            @include gradient-text();
            cursor: pointer;
          }

          .page__navigate-text {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .page__modal-header {
        padding: 10px 24px;
        font-weight: bold;
        font-size: 18px;

        .page__title {
          @include gradient-text();
        }

        .page__id {
          color: #4f4f4f;
        }
      }

      .page__search {
        margin-bottom: 20px;

        .ant-card-head {
          border-bottom: none;
        }

        .ant-card-head-title {
          color: #4f4f4f;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
        }
      }

      .label-detail {
        width: 100%;
        font-size: 13px;
        line-height: 150%;
        color: #8181a5;
        margin-bottom: 2px;
      }

      .page__detail-tabs {
        .ant-tabs {
          overflow: unset !important;
        }
      }

      .page__detail-invoice {
        .page__detail-invoice-header {
          background: #fafafc;
          border-radius: 8px;
          padding: 0px 14px;

          .invoice__row {
            @include gradient_text();
            font-weight: bold;
            font-size: 18px;
          }
        }
      }

      .invoice__delete {
        @include gradient-text-delete();
        font-weight: 600;
      }

      .invoice__add-row {
        .invoice__add-row-text {
          @include gradient-text();
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      .page__detail-total {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 20px;
      }

      .page__detail-text-total {
        @include gradient-text();
        font-weight: 600;
      }

      .page__detail-card-right {
        max-height: 373px;
        font-size: 18px;
      }
    }

    .page__master-table {
      .ant-card {
        height: 100%;
      }

      .table-title {
        color: #4f4f4f;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
      }

      .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
      .ant-table-row-selected {
        background: #ffffff;
        box-shadow: 0px 3px 8px rgba(126, 114, 242, 0.3);
        border-radius: 15px;
      }

      .ant-card-body {
        padding: 0px 10px !important;

        .ant-table-wrapper {
          .ant-table {
            .ant-table-title {
              border: none;
            }

            border-radius: 7px;

            .ant-table-thead {
              tr {
                th {
                  border: none !important;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 18px;
                }
              }
            }

            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner {
              border-color: #365bf8;
            }

            .ant-checkbox-checked .ant-checkbox-inner,
            .ant-checkbox-indeterminate .ant-checkbox-inner:after {
              @include background-color();
            }

            .ant-table-tbody>tr.ant-table-row-selected:hover>td,
            .ant-table-tbody>tr.ant-table-row-selected>td {
              background: #ffffff;
            }

            .ant-table-tbody {
              .ant-table-cell {
                color: #1c1d21;
                font-weight: bold;
                font-size: 13px;
                line-height: 150%;
              }
            }
          }
        }

        tr {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  .mt__1 {
    margin-top: -1.8rem !important;
  }

  .label {
    color: #4f4f4f;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
  }

  // status
  @mixin state {
    font-size: 12px;
    text-transform: none;
    border-radius: 8px;
    padding: 7px 19px 7px 19px;
    white-space: nowrap;
    width: 100px;
    text-align: center;
  }

  .shipped {
    @include state();
    background: linear-gradient(0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)),
      #ffffff;
    background: -moz-linear-gradient(0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)),
      #ffffff;
    background: -webkit-linear-gradient(0deg,
        rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)),
      #ffffff;
    color: #2f80ed;
  }

  .processing {
    @include state();
    color: #f2c94c;
    background: linear-gradient(0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)),
      #ffffff;
    background: -moz-linear-gradient(0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)),
      #ffffff;
    background: -webkit-linear-gradient(0deg,
        rgba(244, 190, 94, 0.1),
        rgba(244, 190, 94, 0.1)),
      #ffffff;
  }

  .cancelled {
    @include state();
    color: #eb5757;
    background: linear-gradient(0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)),
      #ffffff;
    background: -moz-linear-gradient(0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)),
      #ffffff;
    background: -webkit-linear-gradient(0deg,
        rgba(255, 128, 139, 0.1),
        rgba(255, 128, 139, 0.1)),
      #ffffff;
  }

  .error-message {
    font-size: 0.8rem;
  }

  .error-border {
    border-color: $error-color !important;
  }

  .error-text {
    color: $error-color !important;
  }

  .error-background {
    background-color: $error-color !important;
  }

  .table-summary__title {
    @include gradient-text();
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    text-align: right;
  }

  .table-summary__result {
    @include gradient-text();
    font-weight: bold;
    width: 100%;
    text-align: right;
  }

  .confirm-title__container {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    color: $secondary-color;
  }

  .disabled-button {
    cursor: not-allowed;
    pointer-events: none;
  }

  .title-table {
    font-size: 18px;
    line-height: 24px;
    @include gradient-text();
    font-weight: bold;
  }

  .file-modal__wrapper {
    width: 100%;
    padding: 20px;

    .file-modal__container {
      display: flex;
      flex-direction: column;

      .file-modal__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;

        .title__text {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
          color: #0a1629;
        }

        .title__icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #e91616;
          cursor: pointer;

          i {
            color: #fff;
            font-size: 16px;
          }
        }
      }

      .file-modal__content {
        background: #ffffff;
        border-radius: 12px;
        height: 150px;
        border: 1px dashed #d8d8d8;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #1c1d21;

          .content--link {
            text-decoration-line: underline;
            color: #3f8cff;
            cursor: pointer;
          }
        }
      }

      .file-modal__list {
        width: 100%;
      }
    }
  }
}

/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {}

/* (1600x900) HD+ Display */
@media screen and (max-width: 1600px) {}

/* (1366x768) HD+ Display */
@media screen and (max-width: 1366px) {}